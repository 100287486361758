import type { PurchaseItemResponse } from "@9amhealth/openapi";
import asTemplateValue from "lib/asTemplateValue";
import type { ParsedPurchaseItemResponse } from "src/lib/parsePurchaseItem";

export const getMedicationItemSig = (
  item: ParsedPurchaseItemResponse | PurchaseItemResponse
): string => {
  const sig = asTemplateValue(item.metadata["supply.sig"]);
  if (typeof sig === "string") {
    return sig;
  }
  return "";
};
