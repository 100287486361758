import {
  PrescriptionResponse,
  PurchaseItemResponse,
  SubscriptionDetailsResponse
} from "@9amhealth/openapi";
import type { FC } from "react";
import React from "react";
import { checkItemType } from "src/lib/checkItemType";
import { ParsedPurchaseItemResponse } from "src/lib/parsePurchaseItem";
import translate from "src/lib/translate";
import {
  ItemType,
  SubscriptionInterval
} from "src/state/TreatmentPlanCubit/TreatmentPlanCubit";
import PenImg from "src/ui/assets/images/iso-meds-pen.webp";
import pillBottle from "src/ui/assets/images/iso-meds-vial.jpg";
import { MedicationRowItem } from "src/ui/components/MedicationRowItem/MedicationRowItem";
import Collection from "src/ui/components/StyledComponents/Collection";
import CollectionTitle from "src/ui/components/StyledComponents/CollectionTitle";
import Translate from "src/ui/components/Translate/Translate";
import { Wrapper } from "./FullSubscriptionPlanOverviewContent";
import externalItemType = PurchaseItemResponse.externalItemType;
import asTemplateValue from "lib/asTemplateValue";

export enum MetadataKeys {
  CATEGORIES = "medication.categories",
  UNIT_OF_DISPENSE = "medication.unit-of-dispense",
  INITIAL_APPLICATION_DATE = "medication.initial-application-date"
}

enum MedicationCategory {
  DIABETES_GLP1 = "DIABETES_GLP1"
}

export const MedicationSection: FC<{
  parsedMeds: ParsedPurchaseItemResponse[];
  subscription?: SubscriptionDetailsResponse;
}> = ({ parsedMeds, subscription }) => {
  return (
    <Wrapper>
      <CollectionTitle>
        <Translate msg="prescriptionMedication" />
      </CollectionTitle>

      <Collection>
        {parsedMeds.map((item) => {
          const isPlaceholderMed = checkItemType(
            item.externalItemType,
            ItemType.PRELIMINARY_MEDICATION_SUGGESTION
          );

          const description = isPlaceholderMed
            ? translate("plan_overview_placeholder_medication_dosage")
            : item.intake;

          const inlineWarning = item.expiredAt
            ? translate("prescription_valid_missing_lab_values_alert")
            : "";

          const showRefill =
            item.externalItemType !== externalItemType.PRESCRIBED_MEDICATION;

          const categories = (item.metadata[MetadataKeys.CATEGORIES] ??
            []) as MedicationCategory[];
          const isGLP1 = categories.includes(MedicationCategory.DIABETES_GLP1);
          const showPenImage =
            isGLP1 &&
            [
              PrescriptionResponse.doseForm.MILLILITER,
              PrescriptionResponse.doseForm.PEN_NEEDLE
            ].includes(
              asTemplateValue(
                item.metadata[MetadataKeys.UNIT_OF_DISPENSE]
              ) as PrescriptionResponse.doseForm
            );

          return (
            <MedicationRowItem
              key={item.sku}
              sku={item.sku}
              item={item}
              image={showPenImage ? PenImg : pillBottle}
              imageAlt={
                showPenImage
                  ? translate("image.alt.glp1InjectionPen")
                  : translate("image.alt.medicationPillBottle")
              }
              duration={subscription?.renewalInterval as SubscriptionInterval}
              description={description}
              inlineWarning={inlineWarning}
              showRefill={showRefill}
              hidePrice
              hideRemove
            />
          );
        })}
      </Collection>
    </Wrapper>
  );
};
